.selected-heading {
  color: #3578e5;
  position: relative;
}

.selected-heading-wrapper::before {
  content: ' ';
  position: absolute;
  display: inline-block;
  left: -20px;
  top: 10px;
  z-index: 2;
  height: 4px;
  width: 4px;
  background-color: #3578e5;
  border: solid 4px #ccd0d5;
  border-radius: 50%;
}

.normal-heading {
  cursor: pointer;
  line-height: 20px;
  font-size: 16px;
}

.rte-table-of-contents {
  color: #65676b;
  position: sticky;
  display: block;
  float: right;
  z-index: 1;
  max-width: 220px;
  height: 100vh;
}

.rte-table-of-contents ul {
  position: fixed;
  min-width: 220px;
  z-index: 1;
}

.first-heading {
  color: black;
  font-weight: bold;
  margin: 0 2rem 0.5rem;
}

.headings {
  list-style: none;
  margin-top: 0;
  margin-left: 10px;
  padding: 0;
  overflow: scroll;
  height: auto;
  overflow-y: auto;
  border-left: 5px solid #ccd0d5;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.headings::-webkit-scrollbar {
  display: none;
}

.normal-heading-wrapper {
  margin-left: 32px;
  position: relative;
  padding: 4px 0;
}
