.editor-shell {
  margin: 20px auto;
  border-radius: 2px;
  max-width: 1100px;
  color: #000;
  position: relative;
  line-height: 1.7;
  font-weight: 400;
  font-family: 'OptumSans';
}

.editor-shell .editor-container {
  background: none;
  position: relative;
  display: block;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.editor-shell .editor-container.tree-view {
  border-radius: 0;
}

.editor-shell .editor-container.plain-text {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.editor-scroller {
  border: 0;
  display: flex;
  position: relative;
  outline: 0;
  z-index: 0;
  overflow: auto;
  resize: vertical;
}

.editor {
  flex: auto;
  position: relative;
  resize: vertical;
  z-index: -1;
}

pre {
  line-height: 1.1;
  background: #222;
  color: #fff;
  margin: 0;
  padding: 10px;
  font-size: 12px;
  overflow: auto;
  max-height: 400px;
}

pre::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

pre::-webkit-scrollbar-thumb {
  background: #999;
}

.typeahead-popover {
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  position: fixed;
}

.typeahead-popover ul {
  padding: 0;
  list-style: none;
  margin: 0;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: scroll;
}

.typeahead-popover ul::-webkit-scrollbar {
  display: none;
}

.typeahead-popover ul {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.typeahead-popover ul li {
  margin: 0;
  min-width: 180px;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
}

.typeahead-popover ul li.selected {
  background: #eee;
}

.typeahead-popover li {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
}

.typeahead-popover li.active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.typeahead-popover li:first-child {
  border-radius: 8px 8px 0px 0px;
}

.typeahead-popover li:last-child {
  border-radius: 0px 0px 8px 8px;
}

.typeahead-popover li:hover {
  background-color: #eee;
}

.typeahead-popover li .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  min-width: 150px;
}

.typeahead-popover li .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.link-editor .button.active,
.toolbar .button.active {
  background-color: rgb(223, 232, 250);
}

.link-editor .link-input {
  display: block;
  width: calc(100% - 75px);
  box-sizing: border-box;
  margin: 12px 12px;
  padding: 8px 12px;
  border-radius: 15px;
  background-color: #eee;
  font-size: 15px;
  color: rgb(5, 5, 5);
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit;
}

.link-editor .link-view {
  display: block;
  width: calc(100% - 24px);
  margin: 8px 12px;
  padding: 8px 12px;
  border-radius: 15px;
  font-size: 15px;
  color: rgb(5, 5, 5);
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit;
}

.link-editor .link-view a {
  display: block;
  word-break: break-word;
  width: calc(100% - 33px);
}

.link-editor div.link-cancel {
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  margin-right: 28px;
  position: absolute;
  right: -6px;
  top: 18px;
  bottom: 0;
  cursor: pointer;
}

.link-editor div.link-confirm {
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  margin-right: 2px;
  position: absolute;
  right: -9px;
  top: 18px;
  bottom: 0;
  cursor: pointer;
}

.link-editor .link-input a {
  color: rgb(33, 111, 219);
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 30px;
  text-overflow: ellipsis;
}

.link-editor .link-input a:hover {
  text-decoration: underline;
}

.link-editor .font-size-wrapper,
.link-editor .font-family-wrapper {
  display: flex;
  margin: 0 4px;
}

.link-editor select {
  padding: 6px;
  border: none;
  background-color: rgba(0, 0, 0, 0.075);
  border-radius: 4px;
}

.dropdown {
  z-index: 100;
  display: block;
  position: fixed;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  min-height: 40px;
  background-color: #fff;
}

.dropdown .item {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
  max-width: 250px;
  min-width: 100px;
}

.dropdown .item.fontsize-item,
.dropdown .item.fontsize-item .text {
  min-width: unset;
}

.dropdown .item .active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.dropdown .item:first-child {
  margin-top: 8px;
}

.dropdown .item:last-child {
  margin-bottom: 8px;
}

.dropdown .item:hover {
  background-color: #eee;
}

.dropdown .item .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  min-width: 150px;
  margin-left: 8px;
}

.dropdown .item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 12px;
  line-height: 16px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.dropdown .divider {
  width: auto;
  background-color: #eee;
  margin: 4px 8px;
  height: 1px;
}

.dropdown-button-text {
  margin-left: 8px;
  min-width: 76px;
}

@media screen and (max-width: 1100px) {
  .dropdown-button-text {
    display: none !important;
  }

  .dialog-dropdown > .dropdown-button-text {
    display: flex !important;
  }

  .font-size .dropdown-button-text {
    display: flex !important;
  }

  .code-language .dropdown-button-text {
    display: flex !important;
  }
}

.switches {
  z-index: 6;
  position: fixed;
  left: 10px;
  bottom: 70px;
  animation: slide-in 0.4s ease;
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.switch {
  display: block;
  color: #444;
  margin: 5px 0;
  background-color: rgba(238, 238, 238, 0.7);
  padding: 5px 10px;
  border-radius: 10px;
}

#rich-text-switch {
  right: 0;
}

#character-count-switch {
  right: 130px;
}

.switch label {
  margin-right: 5px;
  line-height: 24px;
  width: 100px;
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
}

.switch button {
  background-color: rgb(206, 208, 212);
  height: 24px;
  box-sizing: border-box;
  border-radius: 12px;
  width: 44px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: background-color 0.1s;
  border: 2px solid transparent;
}

.switch button:focus-visible {
  border-color: blue;
}

.switch button span {
  top: 0px;
  left: 0px;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 12px;
  background-color: white;
  transition: transform 0.2s;
}

.switch button[aria-checked='true'] {
  background-color: rgb(24, 119, 242);
}

.switch button[aria-checked='true'] span {
  transform: translateX(20px);
}

.editor-shell span.editor-image {
  cursor: default;
  display: inline-block;
  position: relative;
  user-select: none;
}

.editor-shell .editor-image img {
  max-width: 100%;
  cursor: default;
}

.editor-shell .editor-image img.focused {
  outline: 2px solid rgb(60, 132, 244);
  user-select: none;
}

.editor-shell .editor-image img.focused.draggable {
  cursor: grab;
}

.editor-shell .editor-image img.focused.draggable:active {
  cursor: grabbing;
}

.editor-shell .editor-image .image-caption-container .tree-view-output {
  margin: 0;
  border-radius: 0;
}

.editor-shell .editor-image .image-caption-container {
  display: block;
  position: absolute;
  bottom: 4px;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  border-top: 1px solid #fff;
  background-color: rgba(255, 255, 255, 0.9);
  min-width: 100px;
  color: #000;
  overflow: hidden;
}

.editor-shell .editor-image .image-caption-button {
  display: block;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  width: 30%;
  padding: 10px;
  margin: 0 auto;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  min-width: 100px;
  color: #fff;
  cursor: pointer;
  user-select: none;
}

.editor-shell .editor-image .image-caption-button:hover {
  background-color: rgba(60, 132, 244, 0.5);
}

.editor-shell .editor-image .image-edit-button {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-size: 16px;
  width: 35px;
  height: 35px;
  vertical-align: -0.25em;
  position: absolute;
  right: 4px;
  top: 4px;
  cursor: pointer;
  user-select: none;
}

.editor-shell .editor-image .image-edit-button:hover {
  background-color: rgba(60, 132, 244, 0.1);
}

.editor-shell .editor-image .image-resizer {
  display: block;
  width: 7px;
  height: 7px;
  position: absolute;
  background-color: rgb(60, 132, 244);
  border: 1px solid #fff;
}

.editor-shell .editor-image .image-resizer.image-resizer-n {
  top: -6px;
  left: 48%;
  cursor: n-resize;
}

.editor-shell .editor-image .image-resizer.image-resizer-ne {
  top: -6px;
  right: -6px;
  cursor: ne-resize;
}

.editor-shell .editor-image .image-resizer.image-resizer-e {
  bottom: 48%;
  right: -6px;
  cursor: e-resize;
}

.editor-shell .editor-image .image-resizer.image-resizer-se {
  bottom: -2px;
  right: -6px;
  cursor: nwse-resize;
}

.editor-shell .editor-image .image-resizer.image-resizer-s {
  bottom: -2px;
  left: 48%;
  cursor: s-resize;
}

.editor-shell .editor-image .image-resizer.image-resizer-sw {
  bottom: -2px;
  left: -6px;
  cursor: sw-resize;
}

.editor-shell .editor-image .image-resizer.image-resizer-w {
  bottom: 48%;
  left: -6px;
  cursor: w-resize;
}

.editor-shell .editor-image .image-resizer.image-resizer-nw {
  top: -6px;
  left: -6px;
  cursor: nw-resize;
}

.editor-shell span.inline-editor-image {
  cursor: default;
  display: inline-block;
  position: relative;
  z-index: 1;
}

.editor-shell .inline-editor-image img {
  max-width: 100%;
  cursor: default;
}

.editor-shell .inline-editor-image img.focused {
  outline: 2px solid rgb(60, 132, 244);
}

.editor-shell .inline-editor-image img.focused.draggable {
  cursor: grab;
}

.editor-shell .inline-editor-image img.focused.draggable:active {
  cursor: grabbing;
}

.editor-shell .inline-editor-image .image-caption-container .tree-view-output {
  margin: 0;
  border-radius: 0;
}

.editor-shell .inline-editor-image.position-full {
  margin: 1em 0 1em 0;
}

.editor-shell .inline-editor-image.position-left {
  float: left;
  width: 50%;
  margin: 1em 1em 0 0;
}

.editor-shell .inline-editor-image.position-right {
  float: right;
  width: 50%;
  margin: 1em 0 0 1em;
}

.editor-shell .inline-editor-image .image-edit-button {
  display: block;
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 6px 8px;
  margin: 0 auto;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  min-width: 60px;
  color: #fff;
  cursor: pointer;
  user-select: none;
}

.editor-shell .inline-editor-image .image-edit-button:hover {
  background-color: rgba(60, 132, 244, 0.5);
}

.editor-shell .inline-editor-image .image-caption-container {
  display: block;
  background-color: #f4f4f4;
  min-width: 100%;
  color: #000;
  overflow: hidden;
}

.emoji {
  color: transparent;
  caret-color: rgb(5, 5, 5);
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin: 0 -1px;
}

.emoji-inner {
  padding: 0 0.15em;
}

.emoji-inner::selection {
  color: transparent;
  background-color: rgba(150, 150, 150, 0.4);
}

.emoji-inner::moz-selection {
  color: transparent;
  background-color: rgba(150, 150, 150, 0.4);
}

.rte-actions {
  position: absolute;
  text-align: right;
  margin: 10px;
  bottom: -8px;
  right: 0;
}

.rte-actions.tree-view {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.table-cell-action-button-container {
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
}

.table-cell-action-button {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  position: relative;
  border-radius: 15px;
  color: #222;
  display: inline-block;
  cursor: pointer;
}

.action-button {
  background-color: #eee;
  border: 0;
  padding: 8px 12px;
  position: relative;
  margin-left: 5px;
  border-radius: 15px;
  color: #222;
  display: inline-block;
  cursor: pointer;
}

.action-button:hover {
  background-color: #ddd;
  color: #000;
}

.action-button.save-edit {
  background-color: #A6EAAA;
  color: #222;
}

.action-button.save-edit:hover {
  background-color: #81c784;
  color: #000;
}


.action-button-mic.active {
  animation: mic-pulsate-color 3s infinite;
}

button.action-button:disabled {
  opacity: 0.6;
  background: #eee;
  cursor: not-allowed;
}

@keyframes mic-pulsate-color {
  0% {
    background-color: #ffdcdc;
  }

  50% {
    background-color: #ff8585;
  }

  100% {
    background-color: #ffdcdc;
  }
}

.ltr {
  text-align: left;
}

.rtl {
  text-align: right;
}

.toolbar {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1px;
  background: #fff;
  padding: 4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  vertical-align: middle;
  overflow: auto;
  height: auto;
  position: fixed;
  z-index: 2;
  max-width: 100%;
  min-width: 1240px;
}

button.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  vertical-align: middle;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
}

button.toolbar-item:disabled {
  cursor: not-allowed;
}

button.toolbar-item.spaced {
  margin-right: 2px;
}

button.toolbar-item i.format {
  background-size: contain;
  display: inline-block;
  height: 18px;
  width: 18px;
  vertical-align: -0.25em;
  display: flex;
  opacity: 0.6;
}

button.toolbar-item:disabled .icon,
button.toolbar-item:disabled .text,
button.toolbar-item:disabled i.format,
button.toolbar-item:disabled .chevron-down {
  opacity: 0.2;
}

button.toolbar-item.active {
  background-color: rgba(223, 232, 250, 0.3);
}

button.toolbar-item.active i {
  opacity: 1;
}

.toolbar-item:hover:not([disabled]) {
  background-color: #eee;
}

.toolbar-item.font-family .text {
  display: block;
  max-width: 40px;
}

.toolbar .code-language {
  width: 150px;
}

.toolbar .toolbar-item .text {
  display: flex;
  line-height: 20px;
  vertical-align: middle;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 20px;
  text-align: left;
  padding-right: 10px;
}

.toolbar .toolbar-item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
}

.toolbar i.chevron-down,
.toolbar-item i.chevron-down {
  margin-top: 3px;
  width: 16px;
  height: 16px;
  display: flex;
  user-select: none;
}

.toolbar i.chevron-down.inside {
  width: 16px;
  height: 16px;
  display: flex;
  margin-left: -25px;
  margin-top: 11px;
  margin-right: 10px;
  pointer-events: none;
}

.toolbar .divider {
  width: 1px;
  background-color: #eee;
  margin: 0 4px;
}

.spacer {
  letter-spacing: -2px;
}

button.item i {
  opacity: 0.6;
}

button.item.dropdown-item-active {
  background-color: rgba(223, 232, 250, 0.3);
}

button.item.dropdown-item-active i {
  opacity: 1;
}

.TableNode__contentEditable {
  min-height: 20px;
  border: 0px;
  resize: none;
  cursor: text;
  display: block;
  position: relative;
  outline: 0px;
  padding: 0;
  user-select: text;
  font-size: 15px;
  white-space: pre-wrap;
  word-break: break-word;
  z-index: 3;
}

.dialog-dropdown {
  background-color: #eee !important;
  margin-bottom: 10px;
  width: 100%;
}

.admonition {
  padding: 0.75rem;
  margin: 1rem 0;
  border-radius: 4px;
}

.admonition.note {
  border: 1px solid #1655b8;
  border-left: 4px solid #1655b8;
  background-color: #e9f1ff;
}

.admonition.info {
  border: 1px solid #209cee;
  border-left: 4px solid #209cee;
  background-color: #e0f4ff;
}

.admonition.tip {
  border: 1px solid #007000;
  border-left: 4px solid #007000;
  background-color: #eff6ef;
}

.admonition.question {
  border: 1px solid #6b47d1;
  border-left: 4px solid #6b47d1;
  background-color: #f0e6ff;
}

.admonition.danger {
  border: 1px solid #c40900;
  border-left: 4px solid #c40900;
  background-color: #fcf0f0;
}

.admonition.warning {
  border: 1px solid #f5b702;
  border-left: 4px solid #f5b702;
  background-color: #fef9ea;
}

.admonition p:first-child {
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0.5rem;
}
