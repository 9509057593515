.builder-button-wrapper .save-button-uhc:hover {
    /* Placeholder to add any custom styles for save, preview and 
    publish buttons hover when the theme is uhc */
}

.builder-button-wrapper .save-button-optum-save:hover, 
.save-button-optum-preview:hover,
.save-button-optum-back-to-edit:hover {
    background: #fbf9f4;
    color: #0c55b8;
}
.builder-button-wrapper .save-button-optum-publish:hover {
    color: #fbf9f4;
    background: #0c55b8;
}
#footerLink1 a:hover, #footerLink2 a:hover, #footerLink3 a:hover   {
    text-decoration: none;
}